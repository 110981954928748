// import Footer from "./Footer";
import Header from "./Header";
import Head from "next/head";
import Footer from './Footer';
import { useSelector } from 'react-redux';
const canUseDOM = !!(
    (typeof window !== 'undefined' &&
        window.document && window.document.createElement)
);
function MainLayout({ children,
    page_title,
    page_description,
    onScroll,
    token,
    id,
    page_image,
    page_url,
    hidden,
    user_image,
    meta,
    title,
    // onChange,
    language,
    withFooter = true
}) {
    const footer = useSelector((state) => state.settings?.footer);
    // &callback=getLatLong
    return (
        <div className='main-layout'>
            <Head>
                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
                    integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
                    crossorigin="" />
                <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
                    integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
                    crossorigin=""></script>
                <script src="https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js"></script>
                <script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJvkDZSorzrFzuPZ2byD-gWL0VN7q-jy0"></script>
                <script src="https://unpkg.com/supercluster@7.1.2/dist/supercluster.min.js"></script>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
                <link rel="shortcut icon" href="bcVector.svg" />
                <script crossorigin src="https://unpkg.com/react@16/umd/react.development.js"></script>
                <script crossorigin src="https://unpkg.com/react-dom@16/umd/react-dom.development.js"></script>
                <link rel="stylesheet" type="text/css" charset="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                <link rel="stylesheet" type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                <link rel="stylesheet" href="/css/video-react.css" />
                <title>{page_title ? page_title : title}</title>
                <meta name="description" content={page_description ? page_description : meta} />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                {/* <meta name="robots" content="noindex"></meta> */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={page_url} key="ogurl" />
                <meta property="og:image" content={page_image} key="ogimage" />
                <meta property="og:image:width" content="120" />
                <meta property="og:image:height" content="120" />
                <meta property="og:site_name" content="Beauty Cils" key="ogsitename" />
                <meta property="og:title" content={page_title} key="ogtitle" />
                <meta property="og:description" content={page_description ? page_description : meta} key="ogdesc" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
								
				<script>
				new Image().src = "https://counter.yadro.ru/hit?r"+
				escape(document.referrer)+((typeof(screen)=="undefined")?"":
				";s"+screen.width+"*"+screen.height+"*"+(screen.colorDepth?
				screen.colorDepth:screen.pixelDepth))+";u"+escape(document.URL)+
				";h"+escape(document.title.substring(0,150))+
				";"+Math.random();</script>

                {/* <meta name="description" contact={meta} /> */}

            </Head>
            {hidden ? null :
                <Header
                    language={language}
                    token={token ? token : null}
                    id={id} user_image={user_image}
                // onChange={(value) => onChange(value)}
                />}
            {/* <div id="fb-root"></div>
            {canUseDOM ? <script>{(function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'))}</script> : null}*/}
            {children}
            {withFooter && <Footer language={language} />}
        </div>
    );
}
export default MainLayout;